import Accordion from "react-bootstrap/Accordion";
import ListGroup from "react-bootstrap/ListGroup";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { useState } from "react";
import { connect } from "react-redux";
import { showToastAction } from "../../actions/toastActions";
import Card from "react-bootstrap/Card";
import { useDispatch } from "react-redux";
import FullScreenModal from "../FullScreenModal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { useNavigate } from "react-router-dom";


function CourseDetails({ userReducer: { user }, showToastAction }) {
  const [mainSub, setMainSub] = useState([]);
  const [addSub, setAddSub] = useState([]);
  const [optLangSub, setOptLangSub] = useState([]);
  const [manLangSub, setManLangSub] = useState([]);
  const [selectedSubject, setAdditionalSubject] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubjectSubmission = () => {
    const payload={
      mainSubject:mainSub,
      optionalSubject:addSub,
      mainLanguage:manLangSub,
      optionalLanguage:optLangSub,
    }
    dispatch({type:'UPDATE_SUBJECTS',payload})
    navigate('/account/register')
  }
  const handleCloseModal = () => {
    setAdditionalSubject('');
    setAddSub([])
    setShowModal(false);
  }
  const handlePreview = () => {
    setShowModal(true);
  }

  const handleShowModal = (val) => {
    setShowModal(val);
  }
  const handleMainCheck = (id, val) => {
    setAdditionalSubject("");
    let isPresent = mainSub.find((obj) => obj.value === val);
    if (isPresent) {
      const temp = mainSub.filter((obj) => obj.value !== val);
      setMainSub(temp);
      return;
    }
    setMainSub((prevState) => [...prevState, { id: id, value: val }]);
  };
  const handleAddCheck = (id, val) => {
    let isPresent = addSub.find((obj) => obj.value === val);
    if (isPresent) {
      const temp = addSub.filter((obj) => obj.value !== val);
      setAddSub(temp);
      return;
    }
    setAddSub((prevState) => [...prevState, { id: id, value: val }]);
  };
  const handleOptLangCheck = (id, val) => {
    console.log("handleOptLangCheck", optLangSub);

    let isPresent = optLangSub.find((obj) => obj.value === val);
    if (isPresent) {
      const temp = optLangSub.filter((obj) => obj.value !== val);
      setOptLangSub(temp);
      return;
    }
    setOptLangSub((prevState) => [...prevState, { id: id, value: val }]);
  };
  const handleManLangCheck = (id, val) => {
    console.log("handleManLangCheck", manLangSub);
    let isPresent = manLangSub.find((obj) => obj.value === val);
    if (isPresent) {
      const temp = manLangSub.filter((obj) => obj.value !== val);
      setManLangSub(temp);
      return;
    }
    setManLangSub((prevState) => [...prevState, { id: id, value: val }]);
  };
  const handleContinueClick = () => {
    if (!user.uid) {
      const payload = {
        message: {
          header: "Attention user",
          footer: "Please login to continue..",
        },
        background: "danger",
        isToast: true,
      };
      showToastAction(payload);
    } else {
      if(!mainSub.length){
        const payload = {
          isToast: true,
          message: {
            header: "Attention user",
            footer: "You have not selected any subject",
          },
          background: "danger",
        };
        dispatch({ type: "SHOW_TOAST", payload: payload });
        return;
      }
      let firstElement = mainSub[0].id;
      const isDifferent = mainSub.some((obj) => obj.id !== firstElement);
      if (isDifferent) {
        const payload = {
          isToast: true,
          message: {
            header: "Attention user",
            footer: "Kindly select only one of Arts/Science/Commerce subject",
          },
          background: "danger",
        };
        dispatch({ type: "SHOW_TOAST", payload: payload });
      } else if (mainSub.length !== 3) {
        const payload = {
          isToast: true,
          message: {
            header: "Attention user",
            footer: "Kindly select just 3 Subjects",
          },
          background: "danger",
        };
        dispatch({ type: "SHOW_TOAST", payload: payload });
      } else if (
        optLangSub.length !== 1 ||
        manLangSub.length !== 1
      ) {
        const payload = {
          isToast: true,
          message: {
            header: "Attention user",
            footer: "Choose any 1 language",
          },
          background: "danger",
        };
        dispatch({ type: "SHOW_TOAST", payload: payload });
      } else {
        const subject = mainSub && mainSub[0].id;
        setAdditionalSubject(subject);
      }
    }
  };

  const Arts = {
    id: "m1",
    sub: [
      "History",
      "Political Science",
      "Geography",
      "Economics",
      "Sociology",
      "Psychology",
      "Philosophy",
      "Home Science",
    ],
  };
  const Commerce = {
    id: "m2",
    sub: ["Business Studies", "Accountancy", "Entrepreneurship", "Economics"],
  };
  const Science = {
    id: "m3",
    sub: ["Mathematics", "Physics", "Chemistry", "Biology"],
  };
  const mandatoryLangs = { id: "l1", sub: ["English", "Hindi"] };
  const optionalLangs = {
    id: "l2",
    sub: [
      "English",
      "Hindi",
      "Sanskrit",
      "Bengali",
      "Bhojpuri",
      "Maithili",
      "Urdu",
    ],
  };

  return (
    <>
      <div className="container component-margin">
        <h3 className="text-primary mb-4">Choose courses</h3>
        <Accordion defaultActiveKey="0">
          <h5 className="text-muted mb-2">
            Choose any 3 subjects from any one section*
          </h5>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Arts</Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {Arts &&
                  Arts.sub.map((val, idx) => {
                    return (
                      <ListGroup.Item className=" text-danger" key={idx} action>
                        <Form.Check
                          type="checkbox"
                          onClick={() => handleMainCheck(Arts.id, val)}
                          id={val}
                          label={val}
                        />
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Science</Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {Science &&
                  Science.sub.map((val, idx) => {
                    return (
                      <ListGroup.Item className=" text-danger" key={idx} action>
                        <Form.Check
                          type="checkbox"
                          onClick={() => handleMainCheck(Science.id, val)}
                          id={val}
                          label={val}
                        />
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="2">
            <Accordion.Header>Commerce</Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {Commerce &&
                  Commerce.sub.map((val, idx) => {
                    return (
                      <ListGroup.Item className=" text-danger" key={idx} action>
                        <Form.Check
                          type="checkbox"
                          onClick={() => handleMainCheck(Commerce.id, val)}
                          id={val}
                          label={val}
                        />
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="border border-secondary my-5"></div>
        <Accordion defaultActiveKey="0">
          <h5 className="text-muted mb-2">Choose any 1 Language*</h5>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Languages</Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {mandatoryLangs &&
                  mandatoryLangs.sub.map((val, idx) => {
                    return (
                      <ListGroup.Item
                        className=" text-danger"
                        key={mandatoryLangs.id + val}
                        action
                      >
                        <Form.Check
                          type="checkbox"
                          onClick={() =>
                            handleManLangCheck(mandatoryLangs.id, val)
                          }
                          id={mandatoryLangs.id + val}
                          label={val}
                          disabled={manLangSub.some((obj) => obj.value !== val)}
                        />
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="border border-secondary my-5"></div>
        <Accordion defaultActiveKey="0">
          <h5 className="text-muted mb-2">Choose any 1 Language*</h5>
          <Accordion.Item eventKey="0">
            <Accordion.Header>Languages</Accordion.Header>
            <Accordion.Body>
              <ListGroup>
                {optionalLangs &&
                  optionalLangs.sub.map((val, idx) => {
                    return (
                      <ListGroup.Item
                        className=" text-danger"
                        key={optionalLangs.id + val}
                        action
                      >
                        <Form.Check
                          type="checkbox"
                          onClick={() =>
                            handleOptLangCheck(optionalLangs.id, val)
                          }
                          id={optionalLangs.id + val}
                          label={val}
                          disabled={manLangSub.some((obj) => obj.value === val)}
                        />
                      </ListGroup.Item>
                    );
                  })}
              </ListGroup>
            </Accordion.Body>
          </Accordion.Item>
        </Accordion>
        <div className="border border-secondary my-5"></div>
        {selectedSubject && (
          <Accordion>
            <h5 className="text-muted mb-2">
              Choose any 1 additional subjects
            </h5>
            {selectedSubject === "m1" && (
              <Accordion.Item eventKey="0">
                <Accordion.Header>Arts</Accordion.Header>
                <Accordion.Body>
                  <ListGroup>
                    {Arts &&
                      Arts.sub.map((val, idx) => {
                        return (
                          <ListGroup.Item
                            className=" text-danger"
                            key={Arts.id + val + "add"}
                            action
                          >
                            <Form.Check
                              type="checkbox"
                              onClick={() => handleAddCheck(Arts.id, val)}
                              id={Arts.id + val + "add"}
                              label={val}
                              disabled={mainSub.some(
                                (obj) => obj.value === val
                              )}
                            />
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {selectedSubject === "m3" && (
              <Accordion.Item eventKey="1">
                <Accordion.Header>Science</Accordion.Header>
                <Accordion.Body>
                  <ListGroup>
                    {Science &&
                      Science.sub.map((val, idx) => {
                        return (
                          <ListGroup.Item
                            className=" text-danger"
                            key={Science.id + val + "add"}
                            action
                          >
                            <Form.Check
                              type="checkbox"
                              onClick={() => handleAddCheck(Science.id, val)}
                              id={Science.id + val + "add"}
                              label={val}
                              disabled={mainSub.some(
                                (obj) => obj.value === val
                              )}
                            />
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            )}
            {selectedSubject === "m2" && (
              <Accordion.Item eventKey="2">
                <Accordion.Header>Commerce</Accordion.Header>
                <Accordion.Body>
                  <ListGroup>
                    {Commerce &&
                      Commerce.sub.map((val, idx) => {
                        return (
                          <ListGroup.Item
                            className=" text-danger"
                            key={Commerce.id + val + "add"}
                            action
                          >
                            <Form.Check
                              type="checkbox"
                              onClick={() => handleAddCheck(Commerce.id, val)}
                              id={Commerce.id + val + "add"}
                              label={val}
                              disabled={mainSub.some(
                                (obj) => obj.value === val
                              )}
                            />
                          </ListGroup.Item>
                        );
                      })}
                  </ListGroup>
                </Accordion.Body>
              </Accordion.Item>
            )}
          </Accordion>
        )}
        <Button
          variant="primary"
          className="my-5 float-end"
          size="lg"
          type="submit"
          onClick={selectedSubject ? handlePreview : handleContinueClick}
        >
          Continue
        </Button>
      </div>
      <FullScreenModal title={"Preview"} showModal={showModal} handleShowModal={handleShowModal} handleNextModal={handleSubjectSubmission} handlePrevModal={handleCloseModal}>
        <>
          <div className="container  position-relative " style={{height:'100%',width:'100%'}}>
          <Row className="g-2 my-2">
          <Col md>
            <Card border="primary" className="col">
              <Card.Header>Subjects</Card.Header>
              <Card.Body>
                <Card.Title>Main Subject</Card.Title>
                <Card.Text>
                  <ListGroup as="ol" numbered variant="flush">
                   {mainSub.map(obj=>{
                    return(
                      <ListGroup.Item as="li" key={obj.value}>{obj.value}</ListGroup.Item>
                    )
                   })}
                  </ListGroup>
                </Card.Text>
                <Card.Title>Addtional Subject</Card.Title>
                <Card.Text>
                  <ListGroup as="ol" numbered variant="flush">
                   {addSub.map(obj=>{
                    return(
                      <ListGroup.Item as="li" key={obj.value}>{obj.value}</ListGroup.Item>
                    )
                   })}
                  </ListGroup>
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
            <Col md>
            <Card border="primary" className="col">
              <Card.Header>Languages</Card.Header>
              <Card.Body>
                <Card.Title>Mandatory Language</Card.Title>
                <Card.Text>
                  <ListGroup as="ol" numbered variant="flush">
                   {manLangSub.map(obj=>{
                    return(
                      <ListGroup.Item as="li" key={obj.value}>{obj.value}</ListGroup.Item>
                    )
                   })}
                  </ListGroup>
                </Card.Text>
                <Card.Title>Optional Language</Card.Title>
                <Card.Text>
                  <ListGroup as="ol" numbered variant="flush">
                   {optLangSub.map(obj=>{
                    return(
                      <ListGroup.Item as="li" key={obj.value}>{obj.value}</ListGroup.Item>
                    )
                   })}
                  </ListGroup>
                </Card.Text>
              </Card.Body>
            </Card>
            </Col>
            </Row>

            {/* <div className=" position-absolute  bottom-0 w-100">
              <div  className="d-flex justify-content-between ">
              <Button variant="outline-primary" className="me-auto" size="lg" onClick={handleCloseModal}><i class="fas fa-arrow-left me-2"></i>Return</Button>
              <Button variant="success" size="lg" onClick={handleSubjectSubmission}>Continue to Registeration<i className="fas fa-arrow-right ms-2"></i></Button>
              </div>
            </div> */}
          </div>
        </>
      </FullScreenModal>
    </>
  );
}

const mapStateToProps = (state) => {
  return state;
};

export default connect(mapStateToProps, { showToastAction })(CourseDetails);
