import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import {
  auth,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  signInWithGoogle,
  sendPasswordReset,
  logout,
} from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import { connect, useDispatch } from "react-redux";
import "./index.css";

// import { app } from '../../firebase';

function SignUp({ showModal, handleModal, user }) {
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [isResetPass, setResetPass] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const dispatch = useDispatch();

  useEffect(() => {
    if (showModal) {
      setShow(true);
    }
  }, [showModal]);

  // useEffect(() => {
  //     handleClose();
  // }, [user.uid]);

  const handleClose = () => {
    setShow(false);
    handleModal(false);
  };

  const handleChange = (e) => {
    const { type, value } = e.target;
    if (type === "email") {
      setEmail(value);
    } else if (type === "text") {
      setName(value);
    } else if (type === "tel") {
      setMobile(value);
    } else {
      setPassword(value);
    }
    console.log(email, password, name, mobile);
  };

  const handleResetPassword = async () => {
    if(!email){
      const payloadToast = {
        isToast: true,
        message: {
          header: "Attention user",
          footer: "Email address needed",
        },
        background: "danger",
      };
      dispatch({ type: "SHOW_TOAST", payload: payloadToast });
      return;
    }
    await sendPasswordReset(email);
    setResetPass(false);
   
  };

  const handleLogInWithEmailAndPassword = () => {
   if(email && /^\S+@\S+\.\S+$/.test(email) && password){
    handleClose();
    dispatch({ type: "SHOW_LOADER", payload: { isVisible: true } });
    logInWithEmailAndPassword(email, password);
   }else{
    const payloadToast = {
      isToast: true,
      message: {
        header: "Attention user",
        footer: "Complete all field with valid email/password",
      },
      background: "danger",
    };
    dispatch({ type: "SHOW_TOAST", payload: payloadToast });
   }
  };
  const handleRegisterWithEmailAndPassword = () => {
   
    if(name && email && /^\S+@\S+\.\S+$/.test(email) && password && mobile && /^[0-9]{10}$/.test(mobile)) {
      handleClose();
      dispatch({ type: "SHOW_LOADER", payload: { isVisible: true } });
      registerWithEmailAndPassword(name, mobile, email, password);
    }else{
      const payloadToast = {
        isToast: true,
        message: {
          header: "Attention user",
          footer: "Complete all field name/email/password/mobile",
        },
        background: "danger",
      };
      dispatch({ type: "SHOW_TOAST", payload: payloadToast });
    }
   
  };

  const handleCreateUser = () => {
    setNewUser(true);
    setResetPass(false);
    setEmail('')
    setMobile('');
    setPassword('');
    setName('');
  };
  const handleLoginUser = () => {
    setNewUser(false);
    setEmail('')
    setMobile('');
    setPassword('');
    setName('');
  };

  const handleSignInWithGoogle = () =>{
    handleClose();
    signInWithGoogle()
  }
  // const handleShow = () => setShow(true);

  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{!isNewUser ? "Login" : "Signup"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            {isNewUser ? (
              <>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput1"
                >
                  <Form.Label>Full name</Form.Label>
                  <Form.Control
                    required
                    type="text"
                    placeholder="full name"
                    value={name}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput2"
                >
                  <Form.Label>Mobile number</Form.Label>
                  <Form.Control
                    type="tel"
                    placeholder="Enter number"
                    value={mobile}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="enter password"
                    value={password}
                    onChange={handleChange}
                  />
                </Form.Group>
                <div className="border-text my-4 text-muted">OR</div>
                <p
                  className="text-primary h5 fw-bold text-decoration-underline"
                  role="button"
                  onClick={handleLoginUser}
                >
                  Already a User? Login!
                </p>
              </>
            ) : (
              <>
                <Button
                  variant="danger"
                  size="lg"
                  className="w-100"
                  onClick={handleSignInWithGoogle}
                >
                  <i className="fa-brands fa-google"></i>
                  <span className="ms-2">Login using Google</span>
                </Button>
                <div className="border-text my-4 text-muted">OR</div>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput3"
                >
                  <Form.Label>Email address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="name@example.com"
                    value={email}
                    onChange={handleChange}
                  />
                </Form.Group>
                <Form.Group
                  className="mb-3"
                  controlId="exampleForm.ControlInput4"
                >
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    type="password"
                    placeholder="enter password"
                    value={password}
                    onChange={handleChange}
                    disabled={isResetPass}
                  />
                </Form.Group>
                {!isResetPass && (
                  <p
                    className="text-primary h6 text-decoration-underline w-50"
                    role="button"
                    onClick={() => setResetPass(true)}
                  >
                    Forgot password? Click!
                  </p>
                )}
                <div className="border-text my-4 text-muted">OR</div>
                <p
                  className="text-primary h5 fw-bold text-decoration-underline w-50"
                  role="button"
                  onClick={handleCreateUser}
                >
                  New User? Register!
                </p>
              </>
            )}
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {!isResetPass ? (
            <Button variant="primary" onClick={isNewUser? handleRegisterWithEmailAndPassword : handleLogInWithEmailAndPassword}>
              Submit
            </Button>
          ) : (
            <Button variant="primary" onClick={handleResetPassword}>
              Reset Password
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

const mapStateToProps = (state) => {
  return state.userReducer;
};
export default connect(mapStateToProps, null)(SignUp);
