import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Image from "react-bootstrap/Image";
import { useState, useEffect } from "react";
import Alert from "react-bootstrap/Alert";
import Modal from "react-bootstrap/Modal";
import Progressbar from "../ProgressBar";
import Spinner from "react-bootstrap/Spinner";
import FullScreenModal from "../FullScreenModal";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import { useNavigate } from "react-router-dom";
import Card from "react-bootstrap/Card";
import { useDispatch, useSelector } from "react-redux";

import {
  imageUploader,
  updateUserRegistration,
  imageDeleter,
  getUserDetails,
} from "../../firebase";
import "./index.css";
import DynamicAlert from "../Alert";

function Registeration() {
  const [showModal, setShowModal] = useState(false);
  const [userDetails, setUserDetails] = useState({});
  const [imgUrls, setImgUrl] = useState({});
  const [allImgName, setAllImgName] = useState({});
  const [curFile, setCurFile] = useState({});
  const [curUrl, setCurUrl] = useState("");
  const [alert, setShowAlert] = useState(true);
  const [showSpinner, setShowSpinner] = useState(true);
  const [selectedSubject, setSelectedSubject] = useState("");
  const user = useSelector((state) => state.userReducer.user);
  const subjects = useSelector((state) => state.subjectReducer.subjects);
  const [progresspercent, setProgresspercent] = useState(20);
  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleClose = () => {
    setShow(false);
    setShowSpinner(true);
  };
  const handleShow = () => setShow(true);

  const handleNextModal = async () => {
    setShowModal(false);
    const payload = {
      subjects: subjects,
      details: userDetails,
      images: imgUrls,
    };
    console.log("called handleFormSubmit", payload);
    await updateUserRegistration(payload, user.uid);
    const payloadToast = {
      isToast: true,
      message: {
        header: "Attention user",
        footer: "Form Submitted.We will process your registration",
      },
      background: "success",
    };
    dispatch({ type: "SHOW_TOAST", payload: payloadToast });
    await getUserDetails(user.uid);
    setTimeout(() => {
      navigate("/account/profile");
    }, 1000);
  };
  const handlePrevModal = () => {
    setShowModal(false);
  };

  useEffect(() => {
    const isMyObjectNotEmpty = subjects && Object.keys(subjects).length;
    if (isMyObjectNotEmpty) {
      setShowAlert(false);
    }

    if (subjects && subjects.mainSubject && subjects.mainSubject[0].id) {
      const id = subjects.mainSubject[0].id;
      if (id === "m1") {
        setSelectedSubject("Arts");
      } else if (id === "m2") {
        setSelectedSubject("Commerce");
      } else {
        setSelectedSubject("Science");
      }
    }
  }, [subjects]);

  const handleFileUpload = async (e) => {
    setCurFile(e.target.name);
    const file = e.target.files[0];
    let name = e.target.name;
    if (allImgName.hasOwnProperty(name)) {
      if (!file) {
        const delRef = await imageDeleter(allImgName[name]);
        return;
      } else if (allImgName[name] !== file.name) {
        const delRef = await imageDeleter(allImgName[name]);
      }
    }
    setAllImgName((prevImg) => ({ ...prevImg, [e.target.name]: file.name }));
    setProgresspercent(60);
    const downloadURL = await imageUploader(file);
    setCurUrl(downloadURL);
    handleShow();
    setProgresspercent(100);
    setImgUrl((prevUrls) => ({ ...prevUrls, [e.target.name]: downloadURL }));
    setTimeout(() => {
      setProgresspercent(20);
    }, 100);
  };
  const handleUserDetails = (e) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };
  const handleFormSubmit = (event) => {
    // event.preventDefault();
    // setShowModal(true);
    if (alert) {
      event.preventDefault();
      const payloadToast = {
        isToast: true,
        message: {
          header: "Attention user",
          footer: "Please go to courses first.",
        },
        background: "danger",
      };
      dispatch({ type: "SHOW_TOAST", payload: payloadToast });
      return;
    }
    const form = event.currentTarget;
    setValidated(true);

    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    } else {
      event.preventDefault();
      setShowModal(true);
    }
  };
  const handleShowModal = (val) => {
    setShowModal(val);
  };
  // console.log('deta',userDetails,imgUrls,user,user.uid)
  return (
    <>
      {!user.registration &&
        (alert ? (
          <div style={{ marginTop: "8rem" }} className="container p-0">
            <DynamicAlert variant={"danger"}>
              You have not yet selected subjects. Go to{" "}
              <Alert.Link href="/courses">Courses</Alert.Link> for course
              selection first!
            </DynamicAlert>
          </div>
        ) : (
          <div style={{ marginTop: "8rem" }} className="container p-0">
            <DynamicAlert variant={"success"} title={"Congratulations user!"}>
              You have selected <strong>{selectedSubject}</strong> and
              Languages.
            </DynamicAlert>
          </div>
        ))}
      <Modal show={show} onHide={handleClose} centered>
        <Modal.Header closeButton>
          <Modal.Title className="text-secondary">Image Preview</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="mx-auto text-center">
            {showSpinner && <Spinner animation="border" variant="primary" />}
            <Image src={curUrl} fluid onLoad={() => setShowSpinner(false)} />
          </div>
        </Modal.Body>
      </Modal>
      {!user.registration ? (
        <Form
          className={`my-5 container border p-3 shadow-sm p-3 mb-5 bg-body rounded ${
            alert ? "disabled-form" : ""
          }`}
          onSubmit={handleFormSubmit}
          noValidate
          validated={validated}
        >
          <h2 className="text-center text-decoration-underline">
            Registration Form
          </h2>
          <div className={alert ? 'disabled-fields' : ''}>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Full Name</Form.Label>
            <Form.Control
              type="text"
              name="fullName"
              placeholder="Enter fullname"
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Father's Name</Form.Label>
            <Form.Control
              type="text"
              name="fatherName"
              placeholder="Enter fatherName"
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Mother's Name</Form.Label>
            <Form.Control
              type="text"
              name="motherName"
              placeholder="Enter motherName"
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Full Address</Form.Label>
            <Form.Control
              type="text"
              name="fullAdd"
              placeholder="Enter address.."
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Aaadhar Number</Form.Label>
            <Form.Control
              type="number"
              name="aadharNo"
              placeholder="Enter aadhar number"
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Bank Account Number</Form.Label>
            <Form.Control
              type="number"
              name="accNo"
              placeholder="Enter account number"
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Email address</Form.Label>
            <Form.Control
              type="email"
              name="email"
              placeholder="Enter email"
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Application number</Form.Label>
            <Form.Control
              type="number"
              name="appNo"
              placeholder="Enter application no.."
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Matric Roll Number</Form.Label>
            <Form.Control
              type="number"
              name="rollNo"
              placeholder="Enter rollno.."
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formBasicEmail">
            <Form.Label>Matric Registration Number</Form.Label>
            <Form.Control
              type="number"
              name="regNo"
              placeholder="Enter regno..."
              onChange={handleUserDetails}
              required
            />
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridState">
            <Form.Label>Catgeory</Form.Label>
            <Form.Select
              required
              defaultValue=""
              name="category"
              onChange={handleUserDetails}
            >
              <option disabled value="">
                Choose category...
              </option>
              <option value="Regular">Regular</option>
              <option value="Private">Private</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridState">
            <Form.Label>Medium</Form.Label>
            <Form.Select
              required
              defaultValue=""
              name="medium"
              onChange={handleUserDetails}
            >
              <option disabled value="">
                Choose medium...
              </option>
              <option value="English">English</option>
              <option value="Hindi">Hindi</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridState">
            <Form.Label>Marital status</Form.Label>
            <Form.Select
              required
              defaultValue=""
              name="marriage"
              onChange={handleUserDetails}
            >
              <option disabled value="">
                Choose marriage status...
              </option>
              <option value="Unmarried">Unmarried</option>
              <option value="Married">Married</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridState">
            <Form.Label>Gender</Form.Label>
            <Form.Select
              required
              defaultValue=""
              name="gender"
              onChange={handleUserDetails}
            >
              <option disabled value="">
                Choose gender...
              </option>
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Others">Others</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3" controlId="formGridState">
            <Form.Label>Caste</Form.Label>
            <Form.Select
              required
              defaultValue=""
              name="caste"
              onChange={handleUserDetails}
            >
              <option disabled value="">
                Choose caste...
              </option>
              <option value="General">General</option>
              <option value="EBC">EBC</option>
              <option value="BC">BC</option>
              <option value="SC">SC</option>
              <option value="ST">ST</option>
            </Form.Select>
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>

          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Matric Admit card</Form.Label>
            <Form.Control
              type="file"
              name="AdmitCard"
              onChange={handleFileUpload}
              required
            />
            {progresspercent > 20 && curFile === "AdmitCard" && (
              <Progressbar percentage={progresspercent} />
            )}
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Matric Registraion card</Form.Label>
            <Form.Control
              type="file"
              name="RegCard"
              onChange={handleFileUpload}
              required
            />
            {progresspercent > 20 && curFile === "RegCard" && (
              <Progressbar percentage={progresspercent} />
            )}
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Matric Marksheet</Form.Label>
            <Form.Control
              type="file"
              name="MarkCard"
              onChange={handleFileUpload}
              required
            />
            {progresspercent > 20 && curFile === "MarkCard" && (
              <Progressbar percentage={progresspercent} />
            )}
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload School leaving certificate</Form.Label>
            <Form.Control
              type="file"
              name="SlcCard"
              onChange={handleFileUpload}
              required
            />
            {progresspercent > 20 && curFile === "SlcCard" && (
              <Progressbar percentage={progresspercent} />
            )}
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Caste certificate(if Applicable)</Form.Label>
            <Form.Control
              type="file"
              name="CastCard"
              onChange={handleFileUpload}
            />
            {progresspercent > 20 && curFile === "CastCard" && (
              <Progressbar percentage={progresspercent} />
            )}
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Photo</Form.Label>
            <Form.Control
              type="file"
              name="PhotoCard"
              onChange={handleFileUpload}
              required
            />
            {progresspercent > 20 && curFile === "PhotoCard" && (
              <Progressbar percentage={progresspercent} />
            )}
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          <Form.Group controlId="formFile" className="mb-3">
            <Form.Label>Upload Signature</Form.Label>
            <Form.Control
              type="file"
              name="SignCard"
              onChange={handleFileUpload}
              required
            />
            {progresspercent > 20 && curFile === "SignCard" && (
              <Progressbar percentage={progresspercent} />
            )}
            <Form.Control.Feedback type="invalid">
              required*
            </Form.Control.Feedback>
          </Form.Group>
          </div>
          <Button variant="primary" size="lg" type="submit">
            Submit
          </Button>
        </Form>
      ) : (
        <div
          style={{ marginTop: "8rem" }}
          class=" container border p-3 shadow-sm p-3 mb-5 bg-body rounded"
        >
          <DynamicAlert variant={"success"} title={"Attention"}>
            Registeration already completed!
          </DynamicAlert>
        </div>
      )}

      <FullScreenModal
        title={"Preview"}
        showModal={showModal}
        handleShowModal={handleShowModal}
        handlePrevModal={handlePrevModal}
        handleNextModal={handleNextModal}
      >
        <>
          <div className="container shadow-sm p-3 mb-5 bg-body rounded border  ">
            <Row className="g-2 my-2">
              <Col md>
                <FloatingLabel controlId="floatingInputGrid" label="Full Name">
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.fullName}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel controlId="floatingInputGrid" label="Email">
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.email}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 my-2">
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Father's Name"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.fatherName}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Mother's Name"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.motherName}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 my-2">
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Full Address"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.fullAdd}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Aaadhar Number"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.aadharNo}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 my-2">
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Bank Account Number"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.accNo}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Application number"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.appNo}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 my-2">
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Matric Roll Number"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.rollNo}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel
                  controlId="floatingInputGrid"
                  label="Matric Registration Number"
                >
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.regNo}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 my-2">
              <Col md>
                <FloatingLabel controlId="floatingInputGrid" label="Category">
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.category}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel controlId="floatingInputGrid" label="Medium">
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.medium}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
            <Row className="g-2 my-2">
              <Col md>
                <FloatingLabel controlId="floatingInputGrid" label="Marriage">
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.marriage}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
              <Col md>
                <FloatingLabel controlId="floatingInputGrid" label="Gender">
                  <Form.Control
                    type="text"
                    placeholder="name"
                    value={userDetails.gender}
                    readOnly
                  />
                </FloatingLabel>
              </Col>
            </Row>
            {/* gap */}

            <Row className="g-2 my-2">
              <Col md={3}>
                <Card className="card-height">
                  <Card.Body>
                    <Card.Text>Admit card</Card.Text>
                  </Card.Body>
                  <Card.Img
                    variant="top"
                    src={imgUrls.AdmitCard}
                    style={{
                      height: "100%",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                  <Card.Body>
                    <Card.Text>Registeration card</Card.Text>
                  </Card.Body>
                  <Card.Img
                    variant="top"
                    src={imgUrls.RegCard}
                    style={{
                      height: "100%",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                  <Card.Body>
                    <Card.Text>Mark card</Card.Text>
                  </Card.Body>
                  <Card.Img
                    variant="top"
                    src={imgUrls.MarkCard}
                    style={{
                      height: "100%",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                  <Card.Body>
                    <Card.Text>Photo card</Card.Text>
                  </Card.Body>
                  <Card.Img
                    variant="top"
                    src={imgUrls.PhotoCard}
                    style={{
                      height: "100%",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                  <Card.Body>
                    <Card.Text>Cast card</Card.Text>
                  </Card.Body>
                  <Card.Img
                    variant="top"
                    src={imgUrls.CastCard}
                    style={{
                      height: "100%",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                  <Card.Body>
                    <Card.Text>Slc card</Card.Text>
                  </Card.Body>
                  <Card.Img
                    variant="top"
                    src={imgUrls.SlcCard}
                    style={{
                      height: "100%",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                  <Card.Body>
                    <Card.Text>Sign card</Card.Text>
                  </Card.Body>
                  <Card.Img
                    variant="top"
                    src={imgUrls.SignCard}
                    style={{
                      height: "100%",
                      maxHeight: "300px",
                      objectFit: "contain",
                    }}
                  />
                </Card>
              </Col>
            </Row>

            {/* <div className=" position-absolute  bottom-0 w-100">
              <div  className="d-flex justify-content-between ">
              <Button variant="outline-primary" className="me-auto" size="lg" ><i class="fas fa-arrow-left me-2"></i>Return</Button>
              <Button variant="success" size="lg" >Submit<i className="fas fa-arrow-right ms-2"></i></Button>
              </div>
            </div> */}
          </div>
        </>
      </FullScreenModal>
    </>
  );
}

export default Registeration;
