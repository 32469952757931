import { Navigate, Outlet } from "react-router-dom";
import { auth } from "../../firebase";
import { useEffect } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useSelector, useDispatch } from "react-redux";
import Spinner from "react-bootstrap/Spinner";


export default function PrivateOutlet() {
  const [user, loading, error] = useAuthState(auth);
  // const user = useSelector(state => state.user)
  const dispatch = useDispatch();
  useEffect(() => {
    if (user) {
      console.log("logged-in-outlet", user.uid);
      // dispatch({type: "HIDE_LOADER",payload:{isVisible:false}})
    }
  }, [user]);
  // const auth = useAuth();
  if (loading) {
    // dispatch({type: "SHOW_LOADER",payload:{isVisible:true}})
    return null;
  }
  return user ? <Outlet /> : <Navigate to="/" />;
}
// function useAuth() {
//   return true;
// }
