import React from 'react';
import ListGroup from 'react-bootstrap/ListGroup';
import newGif from '../../assets/newicon.gif';

export default function Updates() {
  return (
    <section id="updates" className='container mx-auto '>
      <div class="d-flex flex-wrap align-items-center border p-1 px-2">
        <div class="col-xs-12 col-md-2 fs-4 my-2"><i class="fa fa-bell me-1"></i>Updates | </div>
        <div class="col-md-10 col-xs-12">
          <marquee behavior="scroll" direction="right" onmouseover="this.stop();" onmouseout="this.start();">
          <ListGroup horizontal >
            <ListGroup.Item > <img src={newGif} alt="new-icon" /><span className="link-primary text-decoration-underline pe-auto" role="button">2021-23 result out</span></ListGroup.Item>
            <ListGroup.Item> <img src={newGif} alt="new-icon" /><span className="link-primary text-decoration-underline pe-auto" role="button">2022 Admit card download</span></ListGroup.Item>
            <ListGroup.Item> <img src={newGif} alt="new-icon" /><span className="link-primary text-decoration-underline pe-auto" role="button">Registeration open for 2022-24 session</span></ListGroup.Item>
            <ListGroup.Item> <img src={newGif} alt="new-icon" /><span className="link-primary text-decoration-underline pe-auto" role="button">Practical centup test 2021-23</span></ListGroup.Item>
            <ListGroup.Item> <img src={newGif} alt="new-icon" /><span className="link-primary text-decoration-underline pe-auto" role="button">Scholorship application open for graduation</span></ListGroup.Item>
          </ListGroup>
        </marquee>
      </div>
      </div>
    </section>
  )
}
