import React from "react";
import pic3 from "../../assets/College-affiliation.png";
import Card from "react-bootstrap/Card";

import Courses from "../Courses";

function About() {
  return (
    <div className="my-10 container">
      <div className="d-flex flex-wrap mt-3">
        <div className="col-12 col-sm-6 p-2">
          <h4 className="text-primary fw-bolder">Who we are?</h4>
          <p className=" fs-5">
            <strong className="fw-bold">
              Rajkeshwar Rao Nanadangarh college, Lauriya
            </strong>{" "}
            was established in 2021 and it is managed by the trust formed under
            Society Registration Act, 1860 and is affiliated by state of bihar
            government.We provide Inter as well as graduation education.It is 5
            km from Lauriya block, towards the east i.e road going to chanpatia.
          </p>
          <div className="mt-3">
            <h4 className="text-primary fw-bolder">Our vision</h4>
            <p className=" fs-5">
              It is our vision to be able to provide most affordable and quality
              education. We like to serve students with utmost care. We belive
              in reducing the efforts of students, so that they can focus more
              on studies than visiting the college for unnecessary
              purposes.Visiting college not only cost time but money as well, so
              we thought to provide all services for students at home except few
              cases.In our research, We found that students waste all most
              500-1000 Rs. & 1 week of time, in only visting college, which we
              can save.
            </p>
          </div>
        </div>

        <div className="col-12 col-sm-6 ">
          <Card>
            {" "}
            <Card.Img
              variant="top"
              src={pic3}
              style={{
                height: "100%",
                objectFit: "contain",
              }}
            />
          </Card>
          {/* <img
            className="d-block w-100"
            src={pic3}
            alt="Rajkeshwar Rao Inter college"
          /> */}
        </div>
      </div>
    </div>
  );
}

export default About;
