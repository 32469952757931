const initialState = {
  toast :{}
}

export default function toastReducer(state=initialState,action){
    switch(action.type){
      case 'SHOW_TOAST' :{
        if(action.payload)
        return {...state,toast:action.payload}
        return state;
      }
      case 'HIDE_TOAST' :{
        if(action.payload)
        return {...state,toast:action.payload}
        return state;
      }
      default : return state;
    }
}