import Col from "react-bootstrap/Col";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Card from "react-bootstrap/Card";
import { useSelector } from "react-redux";
import React, { useState, useEffect } from "react";
import Spinner from "react-bootstrap/Spinner";
import DynamicCarousel from "../DynamicCarousel";
import "./index.css";


function MyProfile() {
  const [userDetails, setUserDetails] = useState({
    aadharNo: "",
    accNo: "",
    appNo: "",
    email: "",
    fatherName: "",
    fullAdd: "",
    fullName: "",
    motherName: "",
    regNo: "",
    rollNo: "",
  });
  const [allImages,setAllImages] = useState(null)
  const user = useSelector((state) => state.userReducer.user);

  const {registration: { details = {},images = {} } = {} } = user || {};

  useEffect(() => {
    if (details.fullName) {
      setUserDetails(details);
    }
    if(images.PhotoCard){
      setAllImages(images);
    }
  }, [details,images]);

  const {
    aadharNo,
    accNo,
    appNo,
    email,
    fatherName,
    fullAdd,
    fullName,
    motherName,
    regNo,
    rollNo,
  } = userDetails;

  if (!user.uid) {
    return (
      <div
        className="position-fixed top-50 start-50"
        style={{ marginTop: "8rem" }}
      >
        <div>
          <Spinner animation="border" variant="primary" />
        </div>
      </div>
    );
  }
  if (!userDetails.fullName) {
    return <h1 className="container" style={{ marginTop: "8rem" }}>No Details,Please complete registeration!</h1>;
  }

  return (
    <div
      className="container shadow-sm p-3 mb-5 bg-body rounded border"
      style={{ marginTop: "8rem" }}
    >
      <Row className="g-2 my-2">
        <Col md>
          <FloatingLabel controlId="floatingInputGrid" label="Full Name">
            <Form.Control
              type="text"
              placeholder="name"
              value={fullName}
              readOnly
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel controlId="floatingInputGrid" label="Email">
            <Form.Control
              type="text"
              placeholder="name"
              value={email}
              readOnly
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="g-2 my-2">
        <Col md>
          <FloatingLabel controlId="floatingInputGrid" label="Father's Name">
            <Form.Control
              type="text"
              placeholder="name"
              value={fatherName}
              readOnly
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel controlId="floatingInputGrid" label="Mother's Name">
            <Form.Control
              type="text"
              placeholder="name"
              value={motherName}
              readOnly
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="g-2 my-2">
        <Col md>
          <FloatingLabel controlId="floatingInputGrid" label="Full Address">
            <Form.Control
              type="text"
              placeholder="name"
              value={fullAdd}
              readOnly
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel controlId="floatingInputGrid" label="Aaadhar Number">
            <Form.Control
              type="text"
              placeholder="name"
              value={aadharNo}
              readOnly
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="g-2 my-2">
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Bank Account Number"
          >
            <Form.Control
              type="text"
              placeholder="name"
              value={accNo}
              readOnly
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Application number"
          >
            <Form.Control
              type="text"
              placeholder="name"
              value={appNo}
              readOnly
            />
          </FloatingLabel>
        </Col>
      </Row>
      <Row className="g-2 my-2">
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Matric Roll Number"
          >
            <Form.Control
              type="text"
              placeholder="name"
              value={rollNo}
              readOnly
            />
          </FloatingLabel>
        </Col>
        <Col md>
          <FloatingLabel
            controlId="floatingInputGrid"
            label="Matric Registration Number"
          >
            <Form.Control
              type="text"
              placeholder="name"
              value={regNo}
              readOnly
            />
          </FloatingLabel>
        </Col>
      </Row>
     {allImages &&  <Row className="g-2 my-2">
              <Col md={3}>
                <Card className="card-height">
                <Card.Body>
                    <Card.Text className="text-secondary">Admit card</Card.Text>
                  </Card.Body>
                  <Card.Img variant="top" src={allImages.AdmitCard} style={{height:'100%',maxHeight:'300px',objectFit:'contain'}}/>
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                <Card.Body>
                    <Card.Text className="text-secondary">Registeration card</Card.Text>
                  </Card.Body>
                  <Card.Img variant="top" src={allImages.RegCard} style={{height:'100%',maxHeight:'300px',objectFit:'contain'}}/>
                  
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                <Card.Body>
                    <Card.Text className="text-secondary">Mark card</Card.Text>
                  </Card.Body>
                  <Card.Img variant="top" src={allImages.MarkCard} style={{height:'100%',maxHeight:'300px',objectFit:'contain'}}/>
                 
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                <Card.Body>
                    <Card.Text className="text-secondary">Photo card</Card.Text>
                  </Card.Body>
                  <Card.Img variant="top" src={allImages.PhotoCard} style={{height:'100%',maxHeight:'300px',objectFit:'contain'}}/>
                 
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                <Card.Body>
                    <Card.Text className="text-secondary">Cast card</Card.Text>
                  </Card.Body>
                  <Card.Img variant="top" src={allImages.CastCard} style={{height:'100%',maxHeight:'300px',objectFit:'contain'}}/>
                  
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                <Card.Body>
                    <Card.Text className="text-secondary">Slc card</Card.Text>
                  </Card.Body>
                  <Card.Img variant="top" src={allImages.SlcCard} style={{height:'100%',maxHeight:'300px',objectFit:'contain'}}/>
                  
                </Card>
              </Col>
              <Col md={3}>
                <Card className="card-height">
                <Card.Body>
                    <Card.Text className="text-secondary">Sign card</Card.Text>
                  </Card.Body>
                  <Card.Img variant="top" src={allImages.SignCard} style={{height:'100%', maxHeight:'300px',objectFit:'contain'}}/>
                  
                </Card>
              </Col>
            </Row>}
    </div>
  );
}

export default MyProfile;
