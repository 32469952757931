import React, { useState, useEffect } from "react";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { connect } from "react-redux";
import { hideToastAction } from "../../actions/toastActions";

function Toasts({toast: { message={}, background = "success", isToast},hideToastAction}) {
  // const [position, setPosition] = useState('top-start');
  const [show, setShow] = useState(false);
  useEffect(() => {
    if (isToast) {setShow(true)};
  }, [isToast]);

  const handleToastClose =() => {
    hideToastAction();
    setShow(false);
  }
  return (
    <ToastContainer
      position='top-end'
      className="p-3"
      containerPosition="fixed"
    >
      <Toast
        onClose={() => handleToastClose()}
        show={show}
        delay={5000}
        bg={background}
        autohide
      >
        <Toast.Header>
          <strong className="me-auto  fw-bold ">
            {message.header ?? "Logged in!"}
          </strong>
        </Toast.Header>
        <Toast.Body className="text-light fw-bolder">
          {message.footer ?? "Welcome Again!"}
        </Toast.Body>
      </Toast>
    </ToastContainer>
  );
}

const mapStateToProps = (state) => {
  return state.toastReducer
};
export default connect(mapStateToProps,{hideToastAction})(Toasts);
