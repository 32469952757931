import React from 'react'
import Row from "react-bootstrap/Row";
import Col from 'react-bootstrap/Col';

export default function Introduction() {
  return (
    <div id="intro" className='container mx-auto bg-primary bg-gradient border border-secondary my-5 p-5'>
      <Row className='d-flex justify-content-between align-items-center w-100 flex-wrap'>
          <Col className='text-center fs-2 fw-bold text-white mb-2'>
            <p><i class="fa fa-graduation-cap fs-1"></i></p>
            <p>1500</p>
            <p>Students</p>
          </Col>
          <Col className='text-center fs-2 fw-bold text-white mb-2'>
            <p><i class="fa fa-cubes fs-1"></i></p>
            <p>3</p>
            <p>Departments</p>
          </Col>
          <Col className='text-center fs-2 fw-bold text-white mb-2'>
            <p><i class="fa fa-person fs-1"></i></p>
            <p>60</p>
            <p>Faculties</p>
          </Col>
          <Col className='text-center fs-2 fw-bold text-white mb-2'>
            <p><i class="fa fa-book fs-1"></i></p>
            <p>17</p>
            <p>Courses</p>
          </Col>
      </Row>
    </div>
  )
}
