import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Spinner from "react-bootstrap/Spinner";
import { useSelector } from "react-redux";

function Loader() {
  const [show, setShow] = useState(true);
  const loader = useSelector((state) => state.loaderReducer.loader);
  useEffect(() => {
    console.log("loading...", loader);
  }, [loader]);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      {loader && loader.isVisible ? (
        <Modal show={show} centered size="sm" style={{zIndex:'2000'}}>
          <div className="w-25 mx-auto position-relative">
            <div className="position-absolute top-50 start-50 translate-middle text-center">
              <Spinner
                animation="grow"
                className="fw-bolder fs-1"
                variant="light"
              />
              <span className="text-light text-center fw-bolder fs-4">
                Loading...
              </span>
            </div>
          </div>
        </Modal>
      ) : null}
    </>
  );
}

export default Loader;
