const initialState = {
  user :{}
}

export default function userReducer(state=initialState,action){
    switch(action.type){
      case 'LOG_IN_USER' :{
        if(action.payload)
        return {...state,uid:action.payload}
        return state;
      }
      case 'GET_USER' :{
        if(action.payload)
        return {...state, user:action.payload}
        return state;
      }
      case 'LOGOUT_USER' :{
        if(action.payload)
        return {...state, user:action.payload}
        return state;
      }
      default : return state;
    }
}