import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
// import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Link, NavLink } from 'react-router-dom'
import logo from '../../assets/college-logo.webp';
import { useEffect,useState} from "react";
import {auth,logout,getUserDetails } from "../../firebase";
import { useAuthState } from "react-firebase-hooks/auth";
import Toast from "../Toast"
import Signup from '../Signup';
import {connect} from 'react-redux'
import OffCanvas from '../OffCanvas';
import { useSelector, useDispatch } from "react-redux";


function NavScrollExample({user}) {
  const [userData, loading, error] = useAuthState(auth);
  const [showModal, setShowModal] = useState(false);
  const [showCanvas, setShowCanvas] = useState(false);
  const dispatch = useDispatch()

 

  useEffect(() => {
    if(loading){
      dispatch({type: "SHOW_LOADER",payload:{isVisible:true}})
    }else{
      dispatch({type: "HIDE_LOADER",payload:{isVisible:false}})
    }
    if (userData) {
      console.log("logged-in-nav",userData.uid);
      getUserDetails(userData.uid)
    }
  }, [userData,loading,error]);

  const handleShowModal = () => {
    setShowModal(true);
  }
  const handleModal = (val) => {
    setShowModal(val);
  }
  const handleCanvas = (val) => {
    setShowCanvas(val);
  }
  const handleShowCanvas = () => {
    setShowCanvas(true);
  }

  const logoutUser = () => {
    // setShow(false);
    // handleCanvas(false);
    logout();
    const payload = {
      isToast: true,
      message: {
        header: "Attention user",
        footer: "Logged out"
      },
    };
    dispatch({ type: "SHOW_TOAST", payload: payload });
    // window.location.reload();
  };

  return (
   <>
    <Navbar bg="light" expand="lg" fixed="top" collapseOnSelect >
      <Container fluid>
        <Navbar.Brand as={Link} to='/' style={{width:"7rem", height:"5rem"}}><img src={logo} alt="logo" style={{width: "100%",height:"100%",objectFit:"contain"}}/></Navbar.Brand>
        <Navbar.Toggle aria-controls="navbarScroll" />
        <Navbar.Collapse id="navbarScroll">
          <Nav
            className="me-auto my-2 my-lg-0"
            style={{ maxHeight: '300px' }}
            navbarScroll
          >
            <Nav.Link  as={NavLink} to='/' eventKey="1">Home</Nav.Link>
            <Nav.Link as={NavLink} to="/about" eventKey="2">About</Nav.Link>
            <Nav.Link as={NavLink} to="/courses" eventKey="3">Courses</Nav.Link>
            <Nav.Link as={NavLink} to="/contacts" eventKey="4">
              Contacts
            </Nav.Link>
          </Nav>
          <Nav.Link eventKey="5" className='d-none d-sm-block'>
          {!(userData && userData.uid)?<div className="m-sm-4" onClick={handleShowModal}>
           <span className="me-2" role="button">Login</span><i className="fa-solid fa-user text-primary fs-3 "></i>
          </div>: <div className="text-primary h5 "><span className="me-2" role="button" onClick={handleShowCanvas}>My Account</span><i className="ms-auto fa-sharp fa-solid fa-caret-down"></i></div>}
          </Nav.Link>
         {!(userData && userData.uid)? <Nav.Link onClick={handleShowModal} className='d-sm-none' eventKey="6">Login</Nav.Link>:
          <NavDropdown title="My Account" id="basic-nav-dropdown" className='d-sm-none'>
              <NavDropdown.Item ><Link to="/account/profile" className="text-decoration-none">My Profile</Link></NavDropdown.Item>
              <NavDropdown.Item >
              <Link to="/account/register" className="text-decoration-none">Registration</Link>
              </NavDropdown.Item>
              <NavDropdown.Item >Form</NavDropdown.Item>
              <NavDropdown.Item >Result</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item  onClick={logoutUser} className="text-primary">
                Logout
              </NavDropdown.Item>
            </NavDropdown>}
        </Navbar.Collapse>
      </Container>
    </Navbar>
    <Signup showModal={showModal} handleModal={handleModal}/>
    {userData && userData.uid && <OffCanvas showCanvas={showCanvas} handleCanvas={handleCanvas}/>}
   </>
  );
}

const mapStateToProps = (state)=>{
  return state.userReducer
}

export default connect(mapStateToProps,null)(NavScrollExample);