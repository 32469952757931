import React, { useState,useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from "react-bootstrap/Button";


function FullScreenModal({title,showModal=false,handleShowModal,handlePrevModal,handleNextModal,children}) {
  const [show, setShow] = useState(false);

  useEffect(() => {
    if(showModal) {
      setShow(true)
    }else{
      setShow(false);
    }
  },[showModal])


  const onHideModal = () => {
    setShow(false);
    handleShowModal(false);
  }

  const handleReturnModal = () => {
    setShow(false);
    handleShowModal(false);
    handlePrevModal()
  }
  const handleSubmitModal = () => {
    setShow(false);
    handleShowModal(false);
    handleNextModal()
  }



  return (
    <>
      <Modal show={show} fullscreen onHide={onHideModal}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{children}</Modal.Body>
        <Modal.Footer>
        <Button onClick={handleReturnModal} variant="outline-primary" className="me-auto" size="lg" ><i class="fas fa-arrow-left me-2"></i>Return</Button>
        <Button onClick={handleSubmitModal} variant="success" size="lg" >Submit<i className="fas fa-arrow-right ms-2"></i></Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default FullScreenModal;