import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import { useEffect, useState } from "react";
import Home from "./components/Home";
import Navbar from "./components/Navbar";
import PrivateOutlet from "./components/PrivateOutlet";
import CourseDetails from "./components/CourseDetails";
import "./App.css";
import ContactDetails from "./components/ContactDetails";
import About from "./components/About";
import Toast from "./components/Toast";
import Registeration from "./components/Registeration";
import Loader from "./components/Loader";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import MyProfile from "./components/MyProfile";
import Privacy from "./components/Privacy";

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Toast />
        <Loader />
        <Routes>
          <Route element={<PrivateOutlet />}>
            <Route path="/account/register" element={<Registeration />} />
            <Route path="/account/profile" element={<MyProfile />} />
          </Route>
          <Route path="/" exact element={<Home />} />
          <Route path="/privacy" exact element={<Privacy />} />
          <Route path="/courses" element={<CourseDetails />} />
          <Route path="/contacts" element={<ContactDetails />} />
          <Route path="/about" element={<About />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;
