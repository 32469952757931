import React, { useState } from 'react';
import Alert from 'react-bootstrap/Alert';
import Button from 'react-bootstrap/Button';

function DynamicAlert({variant,title,children}) {
  const [show, setShow] = useState(true);
    return (
      <Alert variant={variant} >
        <Alert.Heading>{title ?? 'Pay Attention!'}</Alert.Heading>
        <p>
          {children}
        </p>
      </Alert>
    );
}
export default DynamicAlert;