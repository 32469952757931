import React, { useState } from 'react';
import Carousel from 'react-bootstrap/Carousel';
import pic1 from '../../assets/college-1.jpeg';
import pic2 from '../../assets/college-2.jpeg';
import pic3 from '../../assets/college-3.jpeg';
import './index.css'


function ControlledCarousel() {
  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  return (
    <Carousel activeIndex={index} onSelect={handleSelect} className="my-8-custom">
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={pic1}
          alt="First slide"
        />
        <Carousel.Caption className='d-none d-sm-block'>
          <h3>First tech-enabled degree and inter college in Lauriya block</h3>
          <p>We are student first college. Our aim is to provide educational service.</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={pic2}
          alt="Second slide"
        />

        <Carousel.Caption className='d-none d-sm-block'>
          <h3>We care for you!</h3>
          <p>We will help you reaching your goal. Join us in empowering the people of west champaran</p>
        </Carousel.Caption>
      </Carousel.Item>
      <Carousel.Item>
        <img
          className="d-block w-100"
          src={pic3}
          alt="Third slide"
        />

        <Carousel.Caption className='d-none d-sm-block'>
          <h3>Contact us by fillling the form or calling us on our contact number.</h3>
          <p>
            All education and training materials are available at your doorstep
          </p>
        </Carousel.Caption>
      </Carousel.Item>
    </Carousel>
  );
}

export default ControlledCarousel;
