import React, { useState,useEffect } from "react";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import InputGroup from "react-bootstrap/InputGroup";
import Row from "react-bootstrap/Row";
import { auth, signInGuestUser } from "../../firebase";
import {connect} from 'react-redux'
import { useAuthState } from "react-firebase-hooks/auth";
import { useDispatch} from "react-redux";


function ContactForm({userReducer:{user}}) {

  const [validated, setValidated] = useState(false);
  const [show, setShow] = useState(false);
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");

  const dispatch = useDispatch();


  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  useEffect(() => {
   if(!user.name){
    handleShow()
   }else{
    handleClose()
   }
  }, [user]);


  const handleSubmit = async (event) => {
    const form = event.currentTarget;
    setValidated(true);
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
    }else{
      event.preventDefault();
      handleClose();
      dispatch({type: "SHOW_LOADER",payload:{isVisible:true}})
      // const payload={name,email,mobile};
      await signInGuestUser(name, email, mobile);
      const payloadToast = {
        isToast: true,
        message: {
          header: "Attention user",
          footer: "Request confirmed. We will get back.",
        },
        background: "success",
      };
      dispatch({ type: "SHOW_TOAST", payload: payloadToast });
      setValidated(false);
      setEmail('')
      setName('')
      setMobile('')
      dispatch({type: "HIDE_LOADER",payload:{isVisible:false}})

    }

  };
  const handleChange = (e) => {
    const {type,value} = e.target;
    if(type==='email'){
      setEmail(value);
    }else if(type==='text'){
      setName(value);
    }else{
      setMobile(value)
    }
    // else{
    //   setPassword(value)
    // }
    console.log(email,name, mobile);
  };

  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title className="text-danger">Get in touch With us!</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form noValidate validated={validated} onSubmit={handleSubmit}>
          <Row className="mb-3">
          <Form.Group   controlId="validationCustom01" className="my-1">
              <Form.Label>Name*</Form.Label>
              <Form.Control required type="text" placeholder="Enter Name"  value={name} onChange={handleChange}/>
              <Form.Control.Feedback type="invalid">*Name required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group controlId="formGroupEmail" className="my-1">
              <Form.Label>Email address</Form.Label>
              <Form.Control type="email" placeholder="Enter email" value={email} onChange={handleChange}/>
            </Form.Group>
            <Form.Group  controlId="formGroupNumber" className="my-1">
              <Form.Label>Mobile number*</Form.Label>
              <Form.Control type="tel" placeholder="Enter mobile no." pattern="^[0-9]{10}$" required value={mobile} onChange={handleChange}/>
              <Form.Control.Feedback type="invalid">*valid phone number required</Form.Control.Feedback>
            </Form.Group>
            <Form.Group
              controlId="formGroupTextarea"
            >
              <Form.Label>Inquiry</Form.Label>
              <Form.Control as="textarea" rows={3} />
            </Form.Group>
          </Row>
          <Modal.Footer>
        <Button variant="secondary me-auto" onClick={handleClose}>
            Close
          </Button>
        <Button
          variant="primary"
          type="submit"
        >
          Request a callback
        </Button>
      </Modal.Footer>
        </Form>
      </Modal.Body>
     
    </Modal>
  );
}

const mapStateToProps = (state)=>{
  return state
}

export default connect(mapStateToProps,null) (ContactForm);
