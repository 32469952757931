const initialState = {
  subjects :{}
}

export default function subjectReducer(state=initialState,action){
    switch(action.type){
      case 'UPDATE_SUBJECTS' :{
        if(action.payload)
        return {...state,subjects:action.payload}
        return state;
      }
      default : return state;
    }
}