import React from "react";
// import Navbar from "../Navbar";
import Banner from "../Banner";
import Courses from "../Courses";
import ContactForm from "../Contacts";
import Introduction from "../Introduction/Index";
import Updates from "../Updates";
import Footer from "../Footer";


export default function Home() {
  return (
    <>
      <Banner />
      <Updates/>
      <Courses />
      <Introduction />
      <ContactForm />
      <Footer/>
    </>
  );
}
