const initialState = {
  loader :{}
}

export default function loaderReducer(state=initialState,action){
    switch(action.type){
      case 'SHOW_LOADER' :{
        console.log('show loader')
        if(action.payload)
        return {...state,loader:action.payload}
        return state;
      }
      case 'HIDE_LOADER' :{
        console.log('hide loader')
        if(action.payload)
        return {...state,loader:action.payload}
        return state;
      }
      default : return state;
    }
}