import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import {logout} from "../../firebase";
import {
  Link
} from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";



function OffCanvas({ showCanvas, handleCanvas }) {
  const [show, setShow] = useState(false);
  const dispatch = useDispatch()
  useEffect(() => {
    if (showCanvas) {
      console.log("show canvas", showCanvas);
      handleShow();
    }
  }, [showCanvas]);

  const handleClose = () => {
    setShow(false);
    handleCanvas(false);
  };
  const logoutUser = () => {
    setShow(false);
    handleCanvas(false);
    logout();
    const payload = {
      isToast: true,
      message: {
        header: "Attention user",
        footer: "Logged out"
      },
    };
    dispatch({ type: "SHOW_TOAST", payload: payload });
  };
  const handleShow = () => setShow(true);

  return (
    <>
      <Offcanvas show={show} placement="end" onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="list-group list-group-flush">
            <li role="button" className="list-group-item  "><Link to="/account/profile" className="text-decoration-none">My Profile</Link></li>
            <li role="button" className="list-group-item  "><Link to="/account/register" className="text-decoration-none">Registration</Link></li>
            <li role="button" className="list-group-item  ">Form</li>
            <li role="button" className="list-group-item  ">Result</li>
            <li role="button" className="list-group-item text-primary" onClick={logoutUser}>Logout</li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default OffCanvas;
