import { initializeApp } from "firebase/app";
// import { getStorage } from "firebase/storage";
import { ref,getStorage, getDownloadURL, uploadBytesResumable,deleteObject } from "firebase/storage";

// import {showToastAction } from "./actions/toastActions"
import configKey from "./config";
import store from "./stores";
import {
  getAuth,
  signInAnonymously,
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  GoogleAuthProvider,
  signInWithPopup,
  sendPasswordResetEmail,
  signOut,
} from "firebase/auth";
import {
  getFirestore,
  query,
  getDocs,
  collection,
  where,
  addDoc,
  getDoc,
  setDoc,
  doc,
} from "firebase/firestore";


const prodConfig = {
  apiKey: configKey.PROD_KEY,
  authDomain: "rishi-game-begins.firebaseapp.com",
  databaseURL: "https://rishi-game-begins-default-rtdb.firebaseio.com",
  projectId: "rishi-game-begins",
  storageBucket: "rishi-game-begins.appspot.com",
  messagingSenderId: "371215128685",
  appId: "1:371215128685:web:762dfb1f0828d95d6ec764",
  measurementId: "G-JL1RESPXWG",
};

const devConfig = {
  apiKey: configKey.DEV_KEY,
  authDomain: "developement-task.firebaseapp.com",
  projectId: "developement-task",
  storageBucket: "developement-task.appspot.com",
  messagingSenderId: "747948440800",
  appId: "1:747948440800:web:5be4c113c1ee627407a485",
  measurementId: "G-WLC6Z2THEQ"
};

const config = process.env.NODE_ENV === 'production' ? prodConfig : devConfig;


export const app = initializeApp(config);
const storage = getStorage(app);
const auth = getAuth(app);
const db = getFirestore(app);
const googleProvider = new GoogleAuthProvider();

const imageUploader = async (file) => {
  const storageRef = ref(storage, `files/${file.name}`);
  const uploadTask = uploadBytesResumable(storageRef, file);
  let url = await new Promise((resolve, reject) => {
    uploadTask.on(
      "state_changed",
      (snapshot) => {
        const progress = Math.round(
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100
        );
      },
      (error) => {
        alert(error);
      },
      () => {
        getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
          resolve(downloadURL);
        });
      }
    );
  });
  return url;
};


const imageDeleter = async (fileName) => {
  const storageRef = ref(storage, `files/${fileName}`);
  try{
   await deleteObject(storageRef);
   return true;
  }catch(e){
    alert(e);
    return false;
  }
}
const updateUserRegistration = async (userRegistration, uid) => {
  await setDoc(
    doc(db, "test-users", uid),
    {
      registration: userRegistration,
    },
    { merge: true }
  );
};

const signInWithGoogle = async () => {
  try {
    const res = await signInWithPopup(auth, googleProvider);
    const user = res.user;
    store.dispatch({ type: "LOG_IN_USER", payload: user.uid });
    const payload = {
      isToast: true,
    };
    store.dispatch({ type: "SHOW_TOAST", payload: payload });
    const docRef = doc(db, "test-users", user.uid);
    const docSnap = await getDoc(docRef);
    if (!docSnap.exists()) {
      await setDoc(doc(db, "test-users", user.uid), {
        uid: user.uid,
        name: user.displayName,
        authProvider: "google",
        email: user.email,
      });
    } else {
      console.log("document already exists");
    }
 
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const getUserDetails = async (uid) => {
  try {
    const docRef = doc(db, "test-users", uid);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
      const payload = docSnap.data();
      store.dispatch({ type: "GET_USER", payload });
    } else {
      console.log("No such document fetched!");
    }
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logInWithEmailAndPassword = async (email, password) => {
  try {
    const res = await signInWithEmailAndPassword(auth, email, password);
    store.dispatch({ type: "LOG_IN_USER", payload: res.user.uid });
    const payload = {
      isToast: true,
    };
    store.dispatch({ type: "SHOW_TOAST", payload: payload });
  } catch (err) {
    const payload = {
      message: {
        header: "Attention user",
        footer:
          err.code === "auth/wrong-password"
            ? "Wrong password"
            : "User not found",
      },
      background: "danger",
      isToast: true,
    };
    store.dispatch({ type: "SHOW_TOAST", payload: payload });
    console.error(err.message, err.code);
    // alert(err.message);
  }finally{
    store.dispatch({type: "HIDE_LOADER",payload:{isVisible:false}})
  }
};
const registerWithEmailAndPassword = async (name, mobile, email, password) => {
  try {
    const res = await createUserWithEmailAndPassword(auth, email, password);
    store.dispatch({ type: "LOG_IN_USER", payload: res.user.uid });
    const payload = {
      isToast: true,
    };
    store.dispatch({ type: "SHOW_TOAST", payload: payload });
    const user = res.user;
    await setDoc(doc(db, "test-users", user.uid), {
      uid: user.uid,
      name,
      mobile,
      authProvider: "local",
      email,
    });
  } catch (err) {
    console.error(err);
    const payload = {
      message: {
        header: "Attention user",
        footer:
          err.code === "auth/weak-password"
            ? "Weak password"
            : "email-already-in-use",
      },
      background: "danger",
      isToast: true,
    };
    store.dispatch({ type: "SHOW_TOAST", payload: payload });
  }finally{
    store.dispatch({type: "HIDE_LOADER",payload:{isVisible:false}})
  }
};
const sendPasswordReset = async (email) => {
  try {
    await sendPasswordResetEmail(auth, email);
    const payload = {
      message: {
        header: "Attention user",
        footer: "Check your mail inbox or spam",
      },
      background: "success",
      isToast: true,
    };
    store.dispatch({ type: "SHOW_TOAST", payload: payload });
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
const logout = () => {
  signOut(auth);
  store.dispatch({ type: "LOGOUT_USER", payload: {} });
};
const signInGuestUser = async (name, email, mobile) => {
  try {
    const res = await signInAnonymously(auth, name, email, mobile);
    const user = res.user;
    await addDoc(collection(db, `guestUsers`), {
      uid: user.uid,
      name,
      mobile,
      authProvider: "guest",
      email,
    });
    logout();
  } catch (err) {
    console.error(err);
    alert(err.message);
  }
};
export {
  auth,
  imageUploader,
  updateUserRegistration,
  db,
  storage,
  getUserDetails,
  signInWithGoogle,
  signInGuestUser,
  logInWithEmailAndPassword,
  registerWithEmailAndPassword,
  sendPasswordReset,
  logout,
  imageDeleter
};
